import React from 'react';
import { StaticQuery, graphql } from "gatsby"

const NavigationCategories = (props) => {
    return (
        <StaticQuery
        query={graphql`
      query {
        allWpTag {
            nodes {
              name
              slug
            }
          }
          }
      `}
        render={data => (
            data.allWpTag.nodes.map(({ node },index) => (
            <a href={"/category/"+data.allWpTag.nodes[index].slug} className={props.navLinkClass} >{data.allWpTag.nodes[index].name}</a>
            ))            
        )}
        />
    );
};

export default NavigationCategories;