import React, { Component } from 'react';
import "./css/navigationBlog.css"
import {Link} from "gatsby";
import NavigationCategories from "../NavigationCategories"
class NavigationBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerClass: 'hamburger',
            navigationContainerClass:'navigation-container',
            menuCloseWrapClass:'menu-close-wrap',
            navLinkClass:'nav-link',
            navLinkHeadingClass:'nav-link-heading',
        }
        this.hamburgerClick = this.hamburgerClick.bind(this);
        this.hamburgerClose = this.hamburgerClose.bind(this);
    }
    hamburgerClick()
    {
        this.setState({
            hamburgerClass: 'hamburger clicked',
            navigationContainerClass:'navigation-container open',
            menuCloseWrapClass:'menu-close-wrap open',
            navLinkClass:'nav-link show',
            navLinkHeadingClass:'nav-link-heading show'
        })
    }
    hamburgerClose()
    {
        this.setState({
            hamburgerClass: 'hamburger',
            navigationContainerClass:'navigation-container',
            menuCloseWrapClass:'menu-close-wrap',
            navLinkClass:'nav-link',
            navLinkHeadingClass:'nav-link-heading'
        })
    }
    render() {
        return (
            <div className="navigation-block">
                <div className={this.state.navigationContainerClass}>
                    <div className={this.state.hamburgerClass} onClick={this.hamburgerClick}>
                        <div className="menu-top-line"></div>
                        <div className="menu-middle-line"></div>
                        <div className="menu-bottom-line"></div>
                    </div>
                    <div className={this.state.menuCloseWrapClass}>
                        <div className="menu-close-top" onClick={this.hamburgerClose}></div>
                        <div className="menu-close-bottom" onClick={this.hamburgerClose}></div>
                        <div className="menu-heading">Menu</div>
                    </div>
                    <Link to="/" className="text-deco-none"><h1 className={this.state.navLinkHeadingClass}>Home</h1></Link>
                    <Link to="/about" className="text-deco-none"><h1 className={this.state.navLinkHeadingClass}>About Us</h1></Link>
                    <Link to="/team" className="text-deco-none"><h1 className={this.state.navLinkHeadingClass}>Our Team</h1></Link>
                    <h1 className={this.state.navLinkHeadingClass}>Categories</h1>
                        <NavigationCategories navLinkClass={this.state.navLinkClass}>

                        </NavigationCategories>
                        {/* <a href="/category/history" className={this.state.navLinkClass} >History</a>
                        <a href="/category/life" className={this.state.navLinkClass} >Life</a> */}
                    <h1 className={this.state.navLinkHeadingClass}>Jump To</h1>
                        <a href="/quarter/september-2020" className={this.state.navLinkClass}>September '20</a>
                        <a href="/quarter/december-2020" className={this.state.navLinkClass}>December '20</a>                           
                </div>
            </div>
        );
    }
}

export default NavigationBlog;