import styled, { css } from 'styled-components';
import BackgroundImage from "../images/background.png"
export const BodyWrapper = styled.div`
        position: relative;
        left: 0%;
        top: 0%;
        right: 0%;
        bottom: 0%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: auto;
        margin-top: 0px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 0px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        background-image:url(${BackgroundImage});
        background-position: 50% 50%;
        background-size: cover;
        background-attachment: fixed;
        @media screen and (min-width: 1920px) {
            position: static;
            overflow: hidden;
            width: 100%;
            margin-right: auto;
            margin-left: auto;
            padding-right: 0px;
            padding-bottom: 0px;
            background-image: url(${BackgroundImage});
            background-position: 50% 50%;
            background-size: cover;
        }
    `
export const ContentWrapper = styled.div`
    position: relative;
    display: block;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 20px;
    
    @media screen and (max-width: 550px) {
        width: 90%;       
    }
`
export const PostContentWrapper = styled.div`
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    margin-top:12vh;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.25vw;
    line-height: 1.85vw;
    font-weight: 300;
    @media screen and (min-width: 1920px) {    
      margin-top:9vh;
    }
    @media screen and (max-width: 991px) {
      margin-top:9vh;
        width: 80%;
        font-size: 15px;
        line-height: 18px;
    }
    @media screen and (max-width: 767px) {
        width: 85%;    
    }
    @media screen and (max-width: 479px) {
        width: 95%;
    }
`
export const CatalogueMainText = styled.p`
    width: 70%;
    font-family: Roboto, sans-serif;
    color: #2a2a2a;
    font-size: 1.2vw;
    line-height: 1.6vw;
    font-weight: 400;
    text-align: left;
    
    @media screen and (min-width: 1920px) {
        font-family: Roboto, sans-serif;
        font-size: 23px;
        line-height: 1.46em;
        font-weight: 400;
    }
    @media screen and (max-width: 991px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media screen and (max-width: 767px) {
        font-size: 17px;
        line-height:22px;
    }
    @media screen and (max-width: 479px) {

    }
`
export const PostTitle =  styled.h1`
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 1vw;
    font-family: Helveticaneue, sans-serif;
    font-size: 2.7vw;
    line-height: 100%;
    font-weight: 300;
    @media screen and (min-width: 1920px) {        
        position: static;
        width: 100%;
        margin-top: 28px;
        margin-bottom: 10px;
        font-family: Helveticaneue, sans-serif;
        color: #2a2a2a;
        font-size: 50px;
        line-height: 58px;
        font-weight: 300;
    }
    @media screen and (max-width: 991px) {
        font-size: 30px;
        margin-top: 20px;
        margin-bottom:4%;
    }
    @media screen and (max-width: 767px) {
        font-size: 30px;
        padding-left: 10px;
        line-height: 33px;
    }
    @media screen and (max-width: 479px) {
        
        
    }
`
export const EmptyPageHeading = styled.h1`
    margin-top: 40px;
    margin-bottom: 10px;
    font-family: Bodonimt, sans-serif;
    color: #2a2a2a;
    font-size: 55px;
    line-height: 100%;
    font-weight: 400;
  @media screen and (min-width: 1920px) {    
        margin-top: 80px;
        margin-bottom: 0px;
        font-family: Bodonimt, sans-serif;
        font-size: 80px;
        line-height: 100px;
  }
  @media screen and (max-width: 991px){    
        font-size: 45px;
  }
  @media screen and (max-width: 767px){    
        font-size: 40px;
  }
  @media screen and (max-width: 479px) {
        margin-top: 60px;
        font-size: 40px;
  }
`

export const AboutUsWrap = styled.div`
display: block;
width: 80%;
margin-top:12vh;
margin-right: auto;
margin-left: auto;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-webkit-flex-direction: column;
-ms-flex-direction: column;
flex-direction: column;
-webkit-box-pack: center;
-webkit-justify-content: center;
-ms-flex-pack: center;
justify-content: center;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-size: 1.25vw;
line-height: 1.75vw;
font-weight: 300;
& > p {
  width:90%;
  margin-left:auto;
  margin-right:auto;
}
  @media screen and (min-width: 1920px) {    
    margin-top:9vh;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 1%;
    padding-right: 0px;
    padding-bottom: 1%;
  }
  @media screen and (max-width: 991px){  
    margin-top: 9vh;
    font-size: 15px;
    line-height: 18px;
  }
  @media screen and (max-width: 767px){    
        width: 90%;
  }
  @media screen and (max-width: 479px) {
        
  }
`
export const AboutImageBlock = styled.div`
  position: relative;
  margin-bottom: 2%;
  border-radius: 15px;
  width:100%;
  @media screen and (min-width: 1920px) {    
    position: relative;
    margin-bottom: 2%;
  }
  @media screen and (max-width: 991px){  
        
  }
  @media screen and (max-width: 767px){    
    height: auto;
  }
  @media screen and (max-width: 479px) {
        height: 42vh;
  }
`
export const TeamWrap = styled.div`
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  @media screen and (min-width: 1920px) {    
    padding-top: 1%;
    padding-bottom: 1%;
  }
  @media screen and (max-width: 991px){  
      margin-top: 3vh;
  }
  @media screen and (max-width: 767px){    
        width: 90%;
  }
  @media screen and (max-width: 479px) {
    width: 90%;
  }
`
export const TeamHeading = styled.h1`
    width: 100%;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 3%;
    padding-left: 1%;
    font-family: 'Helvetica neu', sans-serif;
    font-size: 2.6vw;
    font-weight: 700;
    @media screen and (min-width: 1920px) {    
        margin-bottom: 1%;
        font-size: 50px;
        line-height: 55px;
      }
      @media screen and (max-width: 991px){  
        font-size: 30px;
        line-height: 40px;
      }
      @media screen and (max-width: 767px){    
        margin-bottom: 8%;
      }
      @media screen and (max-width: 479px) {
        line-height: 35px;
      }
`;
export const TeamGrid = styled.div`
    display: block;
    width: 100%;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 10px;
`;